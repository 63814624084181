import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import { CmsContent, Icon } from "components";
import RenderContent from '../../RenderContent'
import "./Accordion.scss";

const AccordionItem = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return content ? (
    <div
      className={`accordion__item ${isOpen ? "accordion__item--open" : ""}`}
    >
      <div className="accordion__item__header" onClick={() => setIsOpen((previousState) => !previousState)}>
        <RenderContent content={content.heading} className="accordion__item__title" />
        <div className="accordion__item__button">
        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
          <title>Chevron Down</title>
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="48"
            d="M112 184l144 144 144-144"
          />
        </svg>
        </div>
      </div>
      <div className="accordion__item__content">
        {content.content ? <RenderContent content={content.content} /> : null}
      </div>
    </div>
  ) : null;
};

export const Accordion = ({ schema, intro, accordionItems }) => {

  // maps through the content and returns it in the format of the schema
  const schemaContent = accordionItems.map((faq) => {
    return {
      "@type": "Question",
      name: faq.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.content,
      },
    };
  });

  const accordionSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [schemaContent],
  };

  return (
    <section className="accordion">
      <div className="accordion-container">
        <div className="wrap">
          {schema && (
            <Helmet>
              <script type="application/ld+json">{JSON.stringify(accordionSchema)}</script>
            </Helmet>
          )}
          {intro && <RenderContent content={intro} className="accordion__intro" />}
          {Object.keys(accordionItems).length > 0 &&
            accordionItems?.map((item, index) => {
              return <AccordionItem content={item} key={`accordionItem_${index}`} />;
            })}
        </div>
      </div>
    </section>
  );
};
