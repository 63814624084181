import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Image } from '../../Image'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { default as prev } from '../../../images/icons/previous-arrow.svg'
import { default as next } from '../../../images/icons/next-arrow.svg'
import { default as closing } from '../../../images/icons/closing-x.svg'
import '@reach/dialog/styles.css'
import './FilterableGallery.scss'

const FilterableGalleryPass = ({ data }) => {
  const {
    allWordpressWpProducts: { nodes: productNodes } = {
      nodes: [],
    },
  } = data !== 'undefined' ? data : {}

  const [activeFilter, setActiveFilter] = useState('All')

  const [hover, setHover] = useState(false)
  const [activeImage, setActiveImage] = useState(-1)

  const [products, setProducts] = useState([])
  const [images, setImages] = useState([])
  const [isPrevArrowDisabled, setIsPrevArrowDisabled] = useState(true)
  const [isNextArrowDisabled, setIsNextArrowDisabled] = useState(true)

  const [showLightbox, setShowLightbox] = useState(false)
  const [imageToShow, setImageToShow] = useState('')

  const [counterExp, setCounterExp] = useState(
    `${images.indexOf(imageToShow) + 1}/${images.length}`
  )

  const firstGalleryItemRef = useRef(null)

  const open = () => setShowLightbox(true)
  const close = () => setShowLightbox(false)

  useEffect(() => {
    let productArr = productNodes.map(product => product.acf.patioGallery)
    let allProducts = productArr.flat(Infinity)
    setProducts(allProducts)
  }, [])

  // gathers all images of chosen category
  useEffect(() => {
    let imageArr = products.map(product => product?.galleryItem)
    let allImages = imageArr.flat(Infinity)
    setImages(allImages)
  }, [imageToShow])

  // handles image counter changes
  useEffect(() => {
    const counterExpression = `${images.indexOf(imageToShow) + 1}/${
      images.length
    }`
    setCounterExp(counterExpression)
  }, [images])

  // handles arrow updates
  useEffect(() => {
    let currentIndex = images.indexOf(imageToShow)

    currentIndex <= 0
      ? setIsPrevArrowDisabled(true)
      : setIsPrevArrowDisabled(false)

    currentIndex >= images.length - 1
      ? setIsNextArrowDisabled(true)
      : setIsNextArrowDisabled(false)
  }, [imageToShow, images])

  const handleProductChange = e => {
    if (e.target.textContent === 'All') {
      showAll()
      setActiveFilter(e.target.textContent)
    } else {
      let temporary = productNodes.filter(
        productNode => productNode.title === e.target.textContent
      )
      setActiveFilter(e.target.textContent)
      setProducts(temporary[0].acf.patioGallery)
    }
  }

  const showAll = () => {
    let productArr = productNodes.map(product => product.acf.patioGallery)
    let allProducts = productArr.flat(Infinity)
    setActiveFilter('All')
    setProducts(allProducts)
    firstGalleryItemRef.current.focus()
  }

  const showImage = image => {
    let shownImage = image
    setImageToShow(shownImage)
    open()
  }

  const showPrev = e => {
    e.stopPropagation()
    let currentIndex = images.indexOf(imageToShow)

    if (currentIndex <= 0) {
      setIsPrevArrowDisabled(true)
    } else {
      let nextImage = images[currentIndex - 1]
      setImageToShow(nextImage)
      setIsPrevArrowDisabled(false)
    }
  }

  const showNext = e => {
    e.stopPropagation()

    let currentIndex = images.indexOf(imageToShow)
    if (currentIndex >= images.length - 1) {
      setIsNextArrowDisabled(true)
    } else {
      let nextImage = images[currentIndex + 1]
      setImageToShow(nextImage)
      setIsNextArrowDisabled(false)
    }
  }

  const checkForEnterKey = event => {
    const { key } = event
    if (key === 'Enter') {
      firstGalleryItemRef.current.focus()
    }
  }

  return (
    <section className="section-filterable-gallery">
      <div className="filterable-gallery-container">
        <div className="wrap">
          <ul className="filter-navigation">
            <li className={activeFilter === 'All' ? 'active' : ''}>
              <button
                data-value="All"
                onClick={handleProductChange}
                onClick={showAll}
                onKeyPress={showAll}
              >
                All
              </button>
            </li>

            {productNodes.map((product, index) => {
              return (
                <li
                  key={`filter-navigation-key${index}`}
                  onClick={handleProductChange}
                  className={activeFilter === product.title ? 'active' : ''}
                  onKeyPress={checkForEnterKey}
                >
                  <button
                    onClick={handleProductChange}
                    data-value={product.title}
                  >
                    {product.title}
                  </button>
                </li>
              )
            })}
          </ul>
          {showLightbox && (
            <DialogOverlay isOpen={showLightbox} onDismiss={close}>
              <DialogContent className="lightbox" aria-label="image gallery">
                <div
                  className="lightbox-close"
                  onClick={close}
                  onKeyPress={close}
                  tabIndex="1"
                >
                  <img src={closing} alt="close gallery" />
                </div>
                <div className="lightbox-inner">
                  <div
                    className="lightbox-arrow-prev"
                    onClick={showPrev}
                    onKeyPress={showPrev}
                    tabIndex="1"
                    disabled={isPrevArrowDisabled}
                  >
                    <img src={prev} alt="previous" />
                  </div>

                  {imageToShow && (
                    <div className="lightbox-image-wrapper">
                      <Image src={imageToShow} alt="patio" />
                    </div>
                  )}

                  <div
                    className="lightbox-arrow-next"
                    onClick={showNext}
                    onKeyPress={showNext}
                    tabIndex="2"
                    disabled={isNextArrowDisabled}
                  >
                    <img src={next} alt="next" />
                  </div>
                </div>
                <div className="lightbox-counter">{counterExp}</div>
              </DialogContent>
            </DialogOverlay>
          )}
          <div className="gallery" id="gallery">
            {products.map((product, index) => {
              return (
                <button
                  ref={index === 0 ? firstGalleryItemRef : null}
                  className={
                    index === activeImage && hover
                      ? 'gallery-item gallery-item-hovered'
                      : 'gallery-item'
                  }
                  key={`gallery-item-index-${index}`}
                  onClick={() => showImage(product?.galleryItem)}
                  onKeyPress={() => showImage(product?.galleryItem)}
                  onFocus={() => {
                    setHover(true)
                    setActiveImage(index)
                  }}
                  onBlur={() => {
                    setHover(false)
                    setActiveImage(-1)
                  }}
                  onMouseEnter={() => {
                    setHover(true)
                    setActiveImage(index)
                  }}
                  onMouseLeave={() => {
                    setHover(false)
                    setActiveImage(-1)
                  }}
                >
                  {product?.galleryItem && <Image src={product.galleryItem} />}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

FilterableGalleryPass.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProducts: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const FilterableGallery = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpProducts {
            nodes {
              title
              acf {
                patioGallery {
                  galleryItem {
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 1800, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <FilterableGalleryPass {...props} data={data} />}
    />
  )
}
