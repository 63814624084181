import React from 'react'

import './TwoColumn.scss'
import RenderContent from '../../RenderContent'

export const TwoColumn = ({ heading, leftColumn, rightColumn }) => {
  return (
    <section className="section-two-column">
      <div className="two-column-container">
        <div className="wrap">
          <div className="col">
            <h2 dangerouslySetInnerHTML={{__html: heading}} />
            <RenderContent content={leftColumn} />
          </div>
          <div className="col">
            <RenderContent content={rightColumn} />
          </div>
        </div>
      </div>
    </section>
  )
}
