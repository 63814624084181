import React, { Component, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './TestimonialSlider.scss'
import Carousel from '../../Carousel'
import RenderContent from '../../RenderContent'

const TestimonialPass = ({ heading, data }) => {
  const {
    allWordpressWpTestimonials: { nodes: testimonialNodes } = {
      nodes: [],
    },
  } = data !== 'undefined' ? data : {}

  const settings = {
    container: `testimonial-slider-inner`,
    loop: false,
    nav: false,
    controls: true,
    items: 1,
    autoHeight: true,
    slideBy: 'page',
    controlsContainer: '#customise-controls',
    responsive: {
      700: {
        items: 2,
        autoHeight: false,
      },
    },
  }

  return (
    <section className="section-testimonial-slider">
      <div className="testimonial-slider-container">
        <div className="wrap">
          <h2
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <ul
            className="controls"
            id="customise-controls"
            aria-label="Carousel Navigation"
            tabIndex="0"
          >
            <li
              className="prev"
              data-controls="prev"
              aria-controls="customise"
              tabIndex="-1"
            >
              Previous
            </li>
            <li
              className="next"
              data-controls="next"
              aria-controls="customise"
              tabIndex="-1"
            >
              Next
            </li>
          </ul>
          <Carousel settings={settings}>
            {testimonialNodes.map((testimonial, index) => {
              return (
                <div className="slide" key={`testimonial-key-${index}`}>
                  <RenderContent content={testimonial?.content} />
                  <div className="testimonial-details">
                    <div className="testimonial-info">
                      {testimonial?.acf?.reviewersName && (
                        <span className="testimonial-name">
                          {testimonial?.acf?.reviewersName}
                        </span>
                      )}
                      {testimonial?.acf?.reviewLocation && (
                        <span className="testimonial-location">
                          from {testimonial?.acf?.reviewLocation}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export const TestimonialSlider = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              acf {
                reviewersName
                reviewLocation
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}
