import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import './ProductCatalogue.scss'
import { Image } from '../../Image'
import Button from '../../Button'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'

const ProductCataloguePass = ({ heading, data }) => {
  const {
    allWordpressWpProducts: { nodes: productNodes } = {
      nodes: [],
    },
  } = data !== 'undefined' ? data : {}

  return (
    <section className="section-product-catalogue">
      <div className="product-catalogue-container">
        <div className="wrap">
          <h2
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {productNodes.map((product, index) => {
            return (
              <div
                className="product-container"
                key={`ProductCatalogueKey${index}`}
              >
                <div className="product-image">
                  <Image src={product?.acf?.modelImage?.source_url} />
                </div>
                <div className="product-description">
                  <h3 className="block--heading">{product?.title}</h3>
                  {product?.content && (
                    <RenderContent content={product?.content} />
                  )}
                  <div className="button-flex">
                    <Button to="/contact-us/request-a-quote/">
                      Get a Quote
                    </Button>
                    <Button
                      to={product?.acf?.productPageLink?.url}
                      bgColour="white"
                      textColor="black"
                      borderColor="black"
                    >
                      View Range
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const ProductCatalogue = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProducts {
            nodes {
              acf {
                modelImage {
                  source_url
                }
                featureImage {
                  source_url
                }
                productPageLink {
                  url
                  title
                }
              }
              title
              content
            }
          }
        }
      `}
      render={data => <ProductCataloguePass {...props} data={data} />}
    />
  )
}
