import React from 'react'

import './StratcoBlock.scss'
import { Image } from '../../Image'
import Button from '../../Button'
import RenderContent from '../../RenderContent'

export const StratcoBlock = ({
  stratcoTitle,
  stratcoCopy,
  stratcoLink,
  backgroundImage,
  textBackgroundColour,
}) => {
  return (
    <section className="section-stratco-block">
      <div className="finance-block-container">
        <div className="wrap">
          {backgroundImage && (
            <div className="background-container">
              <Image src={backgroundImage} />
            </div>
          )}

          <div
            className={`copy-container copy-container--${textBackgroundColour}`}
          >
            <h2>{stratcoTitle}</h2>

            <RenderContent className="stratco-copy" content={stratcoCopy} />

            {stratcoLink && (
              <Button to={stratcoLink?.url} bgColour="white" textColor="black">
                {stratcoLink?.title}
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
