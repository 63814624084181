import React from 'react'

import { Image } from '../../Image'
import Carousel from '../../Carousel'
import RenderContent from '../../RenderContent'

import './UspSlider.scss'

export const UspSlider = ({ heading, uspPoints }) => {
  const settings = {
    container: `usp-carousel-inner`,
    loop: false,
    nav: false,
    mouseDrag: true,
    controls: true,
    gutter: 30,
    center: true,
    fixedWidth: 300,
    Lazyload: true,
    responsive: {
      1600: {
        items: 2.2,
      },
      1080: {
        items: 2,
        fixedWidth: 347,
      },
      360: {
        items: 1,
        fixedWidth: 347,
      },
    },
    speed: 400,
  }

  return (
    <section className="section-usp-slider">
      <div className="usp-slider-container">
        <div className="wrap">
          <h3
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </div>
      </div>
      <Carousel settings={settings}>
        {uspPoints &&
          uspPoints.map((slide, slide_count) => {
            return (
              // console.log(slide)
              <div className="slide" key={slide_count}>
                <div className="slide-content">
                  {slide?.uspIcon && (
                    <img
                      className="icon"
                      src={slide?.uspIcon?.source_url}
                      alt=""
                    />
                  )}
                  <RenderContent content={slide.uspCopy} />
                </div>
              </div>
            )
          })}
      </Carousel>
    </section>
  )
}
