import React from 'react'

import './FullWidth.scss'
import { RenderShortcode } from '../../RenderShortcode'

export const FullWidth = ({ content }) => {
  return (
    <section className="section-full-width">
      <div className="full-width-container">
        <div className="wrap">
          <RenderShortcode content={content} />
        </div>
      </div>
    </section>
  )
}
