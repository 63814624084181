import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './FeaturedSlider.scss'
import Button from '../../Button'
import { Image } from '../../Image'

function SampleNextArrow(props) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

export const FeaturedSlider = ({ heading, featuredSlider }) => {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <section className="section-featured-slider">
      <div className="featured-slider-container">
        <div className="wrap">
          <h3
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          <Button
            to="/project-gallery/"
            bgColour="white"
            textColor="primary"
            borderColor="primary"
            className="view-all"
          >
            View all
          </Button>
        </div>
      </div>

      <Slider {...settings}>
        {featuredSlider &&
          featuredSlider.map((slide, slide_count) => {
            return (
              <div className="slide" key={slide_count}>
                <div
                  className={`image-container image-container-${slide.imageOrientation}`}
                >
                  <Image src={slide?.featuredImage} />
                </div>
                <span className="patio-style">{slide?.patioStyle}</span>
              </div>
            )
          })}
      </Slider>
    </section>
  )
}
