import React from 'react'

import './StratcoSlider.scss'
import { Image } from '../../Image'
import Carousel from '../../Carousel'
import RenderContent from '../../RenderContent'

export const StratcoSlider = ({ heading, stratcoSlides }) => {
  const settings = {
    container: `stratco-slider-inner`,
    loop: false,
    nav: false,
    mouseDrag: true,
    controls: true,
    gutter: 30,
    center: true,
    items: 1,
    speed: 400,
  }

  return (
    <section className="section-stratco-slider">
      <div className="stratco-slider-container">
        <div className="wrap">
          <h2
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </div>
      </div>
      <div className="slider">
        <Carousel settings={settings}>
          {stratcoSlides &&
            stratcoSlides.map((slide, slide_count) => {
              return (
                // console.log(stratcoSlides?.stratcoImage)
                <div className="slide" key={slide_count}>
                  <Image src={slide?.stratcoImage} />
                  <div className="slide-content">
                    <RenderContent content={slide?.stratcoCopy} />
                  </div>
                </div>
              )
            })}
        </Carousel>
      </div>
    </section>
  )
}
