import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { animated, useSpring } from 'react-spring'
import { chunk } from 'lodash'

import './ProductShowcase.scss'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'

const PatioTitleLink = ({ copy }) => {
  const [{ y }, set] = useSpring(() => ({
    y: -101,
    config: {
      friction: 20,
      tension: 270,
    },
  }))

  return (
    <div
      className="fancy-link-container"
      onMouseEnter={() => set({ y: 0 })}
      onMouseLeave={() => set({ y: -101 })}
    >
      <animated.span
        className="fancy-link"
        style={{ '--translateX-value': y.interpolate(v => `${v}%`) }}
      >
        <h3 className="showcase-title">{copy}</h3>
      </animated.span>
    </div>
  )
}

const ProductShowcasePass = ({ heading, data }) => {
  const {
    allWordpressWpProducts: { nodes: productNodes } = {
      nodes: [],
    },
  } = data !== 'undefined' ? data : {}

  const [featureItem, ...patioGrid] = productNodes
  const { acf, title } = featureItem;
  return (
    <section className="section-product-showcase">
      <div className="product-showcase-container">
        {/* title */}
        <div className="wrap">
          <h2
            className="h2-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          <div className="feature-item-container">
            <div className="feature-item">
              <GatsbyLink
                to={acf?.productPageLink?.url}
                className="item-link"
              >
                <div className="image-container">
                  <Image src={acf?.featureImage} />
                </div>
                <div className="over-content">
                  <PatioTitleLink copy={title} />
                  <div className="content" dangerouslySetInnerHTML={{ __html: acf?.cardText }} />
                </div>
              </GatsbyLink>
            </div>
          </div>

          <div className="showcase-grid">
            {chunk(patioGrid, 2).map((rows, index) => {
              return (
                <div className="row" key={`rowIndex${index}`}>
                  {rows.map((products, patioGridIndex) => {
                    return (
                      // the actual grid items
                      <div
                        className="showcase-item"
                        key={`showcaseItemKey${patioGridIndex}`}
                      >
                        <GatsbyLink
                          to={products?.acf?.productPageLink?.url}
                          className="item-link"
                        >
                          <div className="image-container">
                            <Image src={products?.acf?.featureImage} />
                          </div>
                          <div className="over-content">
                            <PatioTitleLink
                              copy={products?.title}
                              url={products?.acf?.productPageLink?.url}
                            />
                            <div className="content" dangerouslySetInnerHTML={{ __html: products?.acf?.cardText }} />
                          </div>
                        </GatsbyLink>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export const ProductShowcase = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProducts {
            nodes {
              acf {
                featureImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 900, quality: 70) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                cardText
                productPageLink {
                  url
                  title
                }
              }
              title
            }
          }
        }
      `}
      render={data => <ProductShowcasePass {...props} data={data} />}
    />
  )
}
