import React from 'react'

import './FinanceBlock.scss'
import { Image } from '../../Image'
import Button from '../../Button'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'

export const FinanceBlock = ({
  financeCopy,
  financeHeading,
  financeImage,
  financeLink,
  financeSubhead,
}) => {
  return (
    <section className="section-finance-block">
      <div className="finance-block-container">
        <div className="wrap">
          <div className="col">
            <h3>{financeHeading}</h3>
            <p>{financeSubhead}</p>
            <div className="image-container">
              <Image
                src={financeImage}
              />
            </div>
          </div>
          <div className="col">
            <RenderContent className="finance-copy" content={financeCopy} />
            <Button to={financeLink.url}>{financeLink.title}</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
