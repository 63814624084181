import React from 'react'

import { Image } from '../../Image'
import './ImageAndContentBlock.scss'
import { RenderShortcode } from '../../RenderShortcode'

export const ImageAndContentBlock = ({ rows }) => {
  return (
    rows && (
      <section className="section-image-content-block">
        {rows.map((item, index) => {
          return (
            <div
              className="river-container"
              key={`imageContentBlockKey-${index}`}
            >
              <div className="col">
                <div className="image-container">
                  <Image src={item?.image} />
                </div>
              </div>
              <div className="col">
                <RenderShortcode content={item.content} />
              </div>
            </div>
          )
        })}
      </section>
    )
  )
}
