import React from 'react'

import { Image } from '../../Image'
import './Hero.scss'
import { default as logo } from '../../../images/icons/slim-arrow.svg'

export const Hero = ({
  heading,
  content,
  background,
  backgroundColour = 'light',
  imageOptions,
  post = false
}) => {
  const isCompact = () => {
    return !content && !background && imageOptions === 'none'
  }
  return (
    <section
      className={`section-hero section-hero--image-${imageOptions} section-hero--${backgroundColour} ${
        isCompact() ? 'section-hero--compact' : ''
      } ${post ? 'section-hero--post' : ''
      }`}
    >
      <div className="hero-container ">
        <div className="wrap">
          <h1
            className="hero-heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {content && (
            <p className={`hero-content ${!background ? 'w-100' : ''}`}>
              {content}
            </p>
          )}

          {background && (
            <div className="image-container">
              <Image src={background} />
            </div>
          )}
        </div>
        <div className="hero-arrow">
          <img src={logo} />
        </div>
      </div>
    </section>
  )
}
